import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"

const NotFoundPage = ({ data }) => {
  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={headerImg} alt={"Cali Outside Trees"} h1={"Page Not Found"}>
      <SEO
        title="Not Found"
        keywords={["Eyelashes", "Cali Reeves", "Not Found", "404"]}
      />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export const query = graphql`
  query notFoundQuery {
    header: allFile(filter: { name: { eq: "cali_trees_1200x1180" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`
export default NotFoundPage
